<template>
  <div id="moneyHistogramId" style="width: 100%; height: 700px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'MoneyHistogram',
  props: {
    companyMoneyList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    companyMoneyList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    init(arr = []) {
      function truncateToOneDecimal(num) {
        // 将数字转换为字符串，找到小数点的位置
        let str = num.toString()
        let dotIndex = str.indexOf('.')
        // 如果没有小数点，直接返回字符串
        if (dotIndex === -1) {
          return str
        }
        // 截取小数点后一位
        return str.slice(0, dotIndex + 2) // +2 是包括小数点和一位数字
      }
      this.$nextTick(() => {
        var chartDom = document.getElementById('moneyHistogramId')
        var myChart = echarts.init(chartDom)
        var option
        const nameArr = arr.map(v => v.name)
        nameArr.push('资金余额曲线')
        const dataArr = arr.map(v => v.data)
        const xArr = [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月',
        ]
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function (params) {
              const currentMonth = Number(params[0].name) + 1 + '月'
              let tooltipContent = currentMonth + '<br/>'
              const hasPositiveValue = params.some(item => item.value && item.value > 0)
              // 如果存在数据
              if (hasPositiveValue) {
                params.forEach(item => {
                  if (item.seriesType == 'bar') {
                    if (item.seriesName == '收款') {
                      const actualReceipts = Number(dataArr[0][item.dataIndex]).toLocaleString()
                      tooltipContent += `${item.seriesName}: ${actualReceipts}元<br>`
                    }
                    if (item.seriesName == '支出') {
                      const actualExpenditure = Number(dataArr[1][item.dataIndex]).toLocaleString()
                      tooltipContent += `${item.seriesName}: ${actualExpenditure}元<br>`
                    }
                    if (item.seriesName == '资金余额') {
                      const fundBalance = Number(dataArr[2][item.dataIndex]).toLocaleString()
                      tooltipContent += `${item.seriesName}: ${fundBalance}元<br>`
                    }
                  }
                })
              } else {
                tooltipContent += '无数据' // 如果没有数据，则返回 '无数据'
              }
              return tooltipContent
            },
          },
          legend: {
            data: nameArr,
            formatter: function (name) {
              const textArr = nameArr.map(v => ({
                name: v,
                text: v,
              }))
              const target = textArr.find(item => item.text === name)
              if (target) {
                return target.text
              }
              return name
            },
          },
          xAxis: [
            {
              type: 'category',
              gridIndex: 0,
              show: false,
            },
            {
              type: 'category',
              interval: 0,
              gridIndex: 1,
              data: xArr,
            },
          ],
          yAxis: [
            {
              type: 'log',
              gridIndex: 0,
              min: 1, // 设置y轴最小值
              axisLabel: {
                formatter: val => {
                  if (val === 1.01) {
                    return 0
                  } else if (val >= 100000000) {
                    return val / 100000000 + '亿' // 大于等于1亿
                  } else if (val >= 10000) {
                    return val / 10000 + 'W' // 大于等于1万
                  }
                  return val // 其他情况下直接返回原数值
                },
              },
            },
            {
              type: 'value',
              gridIndex: 1,
              show: false,
            },
          ],
          grid: [
            {
              right: 20,
              left: 80,
              top: 30,
              bottom: 50,
              containLabel: false,
            },
            {
              right: 20,
              left: 80,
              top: '50%',
              bottom: 30,
              containLabel: false,
              show: false,
            },
          ],
          series: [
            {
              name: nameArr[0],
              xAxisIndex: 0,
              yAxisIndex: 0,
              barWidth: '25%',
              barGap: '0%', // 中间不间隔
              type: 'bar',
              color: '#0064ff', // 标签颜色
              data: dataArr[0]?.map(item => (item > 0 ? (item > 1 ? item : 1.01) : null)), // 正数柱状图series.data只展示大于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return value // 其他情况下直接返回原数值
                },
                fontSize: 10,
                fontWeight: 'bold',
                color: '#0064ff', // 标签颜色
              },
            },
            {
              name: nameArr[2],
              xAxisIndex: 0,
              yAxisIndex: 0,
              barWidth: '25%',
              type: 'bar',
              barGap: '0%', // 中间不间隔
              color: '#f8d347', // 标签颜色
              data: dataArr[2]?.map(item => (item > 0 ? (item > 1 ? item : 1.01) : null)), // 正数柱状图series.data只展示大于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return value // 其他情况下直接返回原数值
                },
                fontSize: 10,
                fontWeight: 'bold',
                color: '#f8d347', // 标签颜色
              },
            },
            {
              name: nameArr[1],
              xAxisIndex: 0,
              yAxisIndex: 0,
              barWidth: '25%',
              type: 'bar',
              barGap: '0%', // 中间不间隔
              color: '#ff6c60', // 标签颜色
              data: dataArr[1]?.map(item => (item > 0 ? (item > 1 ? item : 1.01) : null)), // 正数柱状图series.data只展示大于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return value // 其他情况下直接返回原数值
                },
                fontSize: 10,
                fontWeight: 'bold',
                color: '#ff6c60', // 标签颜色
              },
            },

            {
              name: nameArr[3],
              yAxisIndex: 0,
              type: 'line',
              smooth: true, // 平滑折线
              show: true,
              symbolOffset: [0, 0],
              // symbol: 'arrow',
              data: dataArr[2]?.map(value => (value <= 0 ? 1.01 : value)),
              color: '#f8d347', // 标签颜色
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  let value = dataArr[2][params.dataIndex] // 获取当前柱子的数值
                  if (value <= 0) {
                    value = Math.abs(value)
                    if (value >= 100000000) {
                      return '-' + truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                    } else if (value >= 10000) {
                      return '-' + truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                    } else if (value >= 1000) {
                      return '-' + truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                    }
                    if (value == 0) {
                      return 0
                    }
                    return '-' + value
                  } else {
                    return ''
                  }
                },
                fontSize: 10,
                fontWeight: 'bold',
                color: '#f8d347', // 标签颜色
              },
            },
            {
              name: nameArr[0],
              yAxisIndex: 0,
              type: 'line',
              smooth: true, // 平滑折线
              show: false,
              lineStyle: {
                width: 0,
              },
              symbol: 'arrow',
              symbolOffset: [-35, 0],
              data: dataArr[0]?.map(value => (value <= 0 ? 1.01 : value)),

              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  let value = dataArr[0][params.dataIndex] // 获取当前柱子的数值
                  if (value <= 0) {
                    value = Math.abs(value)
                    if (value >= 100000000) {
                      return '-' + truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                    } else if (value >= 10000) {
                      return '-' + truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                    } else if (value >= 1000) {
                      return '-' + truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                    }
                    if (value == 0) {
                      return 0
                    }
                    return '-' + value
                  } else {
                    return ''
                  }
                },
                fontSize: 10,
                fontWeight: 'bold',
                color: '#0064ff', // 标签颜色
              },
            },
            {
              name: nameArr[1],
              yAxisIndex: 0,
              type: 'line',
              smooth: true, // 平滑折线
              show: false,
              symbolOffset: [35, 0],
              lineStyle: {
                width: 0,
              },
              symbol: 'arrow',
              data: dataArr[1]?.map(value => (value <= 0 ? 1.01 : value)),
              color: '#ff6c60', // 标签颜色
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  let value = dataArr[1][params.dataIndex] // 获取当前柱子的数值
                  if (value <= 0) {
                    value = Math.abs(value)
                    if (value >= 100000000) {
                      return '-' + truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                    } else if (value >= 10000) {
                      return '-' + truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                    } else if (value >= 1000) {
                      return '-' + truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                    }
                    if (value == 0) {
                      return 0
                    }
                    return '-' + value
                  } else {
                    return ''
                  }
                },
                fontSize: 10,
                fontWeight: 'bold',
                color: '#ff6c60', // 标签颜色
              },
            },
          ],
        }
        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
